import React from 'react'
import TableCell from '@mui/material/TableCell'

export default function generate({ t }) {
  return [
    {
      name: 'controlNumber',
      label: t('common.control-number'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="controlNumber-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.control-number')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'vehicleType',
      label: t('common.vehicleType'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="vehicleType-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.vehicleType')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'vehicleCount',
      label: t('common.vehicle-count'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="vehicleCount-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.vehicle-count')}
            </TableCell>
          )
        },
      },
    },
    {
      name: 'parkNumbers',
      label: t('common.nParc'),
      options: {
        sort: true,
        customHeadRender: () => {
          return (
            <TableCell
              key="nParc-translation"
              style={{ fontWeight: 'bolder', fontSize: 12 }}
            >
              {t('common.nParc')}
            </TableCell>
          )
        },
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
