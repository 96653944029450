import React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledMUIDataTable = styled(MUIDataTable, {
  shouldForwardProp: (prop) => prop !== 'clickableRow',
})(({ clickableRow }) => ({
  ...(clickableRow && {
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  }),
}));

BFTable.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.object,
  page: PropTypes.number,
  sort: PropTypes.any,
  onRowClick: PropTypes.func,
  onChangePage: PropTypes.func,
  onColumnSortChange: PropTypes.func,
  pagination: PropTypes.bool,
  selectableRows: PropTypes.any,
  selectToolbarPlacement: PropTypes.string,
  rowsSelected: PropTypes.array,
  onRowSelectionChange: PropTypes.func,
  menuItems: PropTypes.array,
  onCellClick: PropTypes.func,
  customRowRender: PropTypes.func,
  customFooterRender: PropTypes.func,
  noDataMessage: PropTypes.string,
};

export default function BFTable({
  id,
  columns,
  data,
  page,
  sort,
  onRowClick,
  onChangePage,
  onColumnSortChange,
  pagination,
  selectableRows,
  selectToolbarPlacement,
  rowsSelected,
  onRowSelectionChange,
  menuItems,
  onCellClick,
  customRowRender,
  customFooterRender,
  noDataMessage,
}) {
  const { t } = useTranslation();

  const sortOrder = sort ? { name: sort.field, direction: sort.direction.toLowerCase() } : {};

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event, rowIndex) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
          rowIndex,
        }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const createMenuItemComponents = (rowIndex) =>
    menuItems(rowIndex).map((component) => {
      const originalOnClick = component.props.onClick;
      return React.cloneElement(component, {
        onClick: () => {
          handleClose();
          if (originalOnClick) {
            originalOnClick();
          }
        },
      });
    });

  return (
    data && (
      <>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          disableAutoFocusItem
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        >
          {contextMenu && createMenuItemComponents(contextMenu.rowIndex)}
        </Menu>
        <StyledMUIDataTable
          clickableRow={!!onRowClick}
          key={id}
          data={data.items}
          columns={columns}
          options={{
            enableNestedDataAccess: '.',
            page,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            download: false,
            selectableRows: selectableRows || 'none',
            selectToolbarPlacement: selectToolbarPlacement || 'none',
            selectableRowsHeader: data && data.itemCount > 0,
            rowsSelected,
            onRowSelectionChange,
            rowsPerPage: data.range.limit,
            rowsPerPageOptions: [],
            count: data.itemCount,
            serverSide: true,
            sortOrder,
            onChangePage,
            onColumnSortChange,
            pagination,
            onRowClick,
            onCellClick,
            customRowRender,
            customFooter: customFooterRender,
            setRowProps: menuItems
              ? (row, dataIndex) => ({
                onContextMenu: (event) => handleContextMenu(event, dataIndex),
              })
              : undefined,
            textLabels: {
              body: {
                noMatch: noDataMessage ?? t('components.table.body.noMatch'),
                toolTip: t('components.table.body.toolTip'),
              },
              pagination: {
                next: t('components.table.pagination.next'),
                previous: t('components.table.pagination.previous'),
                rowsPerPage: t('components.table.pagination.rowsPerPage'),
                displayRows: t('components.table.pagination.displayRows'),
              },
              toolbar: {
                search: t('components.table.toolbar.search'),
                downloadCsv: t('components.table.toolbar.downloadCsv'),
                print: t('components.table.toolbar.print'),
                viewColumns: t('components.table.toolbar.viewColumns'),
                filterTable: t('components.table.toolbar.filterTable'),
              },
              filter: {
                all: t('components.table.filter.all'),
                title: t('components.table.filter.title'),
                reset: t('components.table.filter.reset'),
              },
              viewColumns: {
                title: t('components.table.viewColumns.title'),
                titleAria: t('components.table.viewColumns.titleAria'),
              },
              selectedRows: {
                text: t('components.table.selectedRows.text'),
                delete: t('components.table.selectedRows.delete'),
                deleteAria: t('components.table.selectedRows.deleteAria'),
              },
            },
          }}
        />
      </>
    )
  );
}
